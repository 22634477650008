.rmaImportBody {
  position: initial;
  width: 1200px;
  height: 800px;
  left: 100px;
  right: 100px;
  top: 76px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
}

.rmaImportModalBody {
  position: absolute;
  width: 400px;
  height: auto;
  left: 437px;
  top: 290px;
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  box-shadow: 3px 3px 0px rgba(0, 0, 0, 0.25);
}

.rmaheading {
  position: static;
  width: 171px;
  height: 32px;
  left: 0px;
  top: 0px;
  font-family: Apercu Pro;
  font-size: 24px;
  lineheight: 135%;
  display: flex;
  align-items: center;
  color: #000000;
  flex: none;
  order: 0;
  align-self: center;
  margin: 0px 0px;
}

.rmaImportModalText {
  position: initialnpm;
  height: 24px;
  left: 16px;
  top: 64px;
  font-family: Apercu Pro;
  font-size: 16px;
  lineheight: 150%;
  display: flex;
  align-items: center;
  color: #000000;
}

.rmaImportRadioBtnText {
  position: static;
  left: 40px;
  right: 0px;
  top: 0%;
  bottom: 0%;
  font-family: Apercu Pro;
  font-size: 16px;
  lineheight: 150%;
  display: flex;
  align-items: center;
  color: #000000;
  flex: none;
  order: 1;
  align-self: center;
  margin: 16px 0px;
}

.rmaImportRadioBtn {
  position: static;
  width: 24px;
  height: 24px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 999px;
  flex: none;
  order: 0;
  align-self: center;
  margin: 1px 10px;
}

.rmaImportRadioBtn1 {
  position: absolute;
  width: 12px;
  height: 12px;
  left: calc(50% - 12px / 2);
  top: calc(50% - 12px / 2);
  background: #000000;
}

.returnToDashboardBtn {
  position: static;
  width: 180px;
  height: 24px;
  left: 12px;
  top: 4px;
  font-family: Apercu Pro;
  font-size: 16px;
  lineheight: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #008080;
  flex: none;
  order: 0;
  align-self: center;
  margin: 0px 10px;
}

.continueBtn {
  position: static;
  width: 65px;
  height: 24px;
  left: 12px;
  top: 4px;
  font-family: Apercu Pro;
  font-size: 16px;
  lineheight: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #008080;
  flex: none;
  order: 0;
  align-self: center;
  margin: 0px 10px;
}

.rmaUploadFileBox {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
}

.createRMAinImportButton {
  display: flex;
  flex-direction: column;
  padding: 15px 15px;
  position: inherit;
  width: 120px;
  height: 52px;
  left: 576px;
  background: #377a80;
  border-radius: 6px;
  color: white;
}

.createRMASummaryButton {
  display: flex;
  flex-direction: column;
  padding: 15px 15px;
  position: fixed;
  width: 120px;
  height: 52px;
  left: 576px;
  background: #377a80;
  border-radius: 6px;
  color: white;
}

.ScanNavLabel {
  position: static;
  width: 220px;
  height: 12px;
  left: 32px;
  top: 0px;
  font-family: Apercu Pro;
  font-size: 16px;
  lineheight: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #377a80;
  flex: none;
  order: 1;
  align-self: center;
  margin: 0px 0px;
  a:hover {
    color: #377a80;
  }
}
.scanLeftArraow {
  position: static;
  width: 24px;
  height: 24px;
  left: 0px;
  top: 0px;
  flex: none;
  order: 0;
  align-self: center;
  margin: 8px 0px;
}
.backtoDashboard {
  position: static;
  width: 180px;
  height: 24px;
  left: 32px;
  top: 0px;
}
.rmaTitle {
  font-family: Apercu Pro;
  font-size: 40px;
  lineheight: 115%;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 1);
}
.summaryleftdiv {
  height: 40px;
  font-family: Apercu Pro;
}
.rmaFiledashboardtable {
  border-spacing: 0px;
  margin-bottom: 10px;
  width: 100%;
  .pagination {
    padding: 0.5rem;
  }
}

.summaryrightdiv {
  display: flex;
  height: 40px;
  flex-direction: row;
  font-family: Apercu Pro;
}
.summaryupperdiv {
}
.summarylowerdiv {
}

.mainTablediv {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.summaryHeaders {
  display: flex;
  flex-direction: row;
  align-content: space-between;
  width: 95%;
  justify-content: space-between;
  margin-bottom: 10px;
}
.box {
  display: inline-flex;
  justify-content: space-between;
}
.manualSummarydiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 10px 0px;

  button {
    position: unset;
    margin-right: 10px;
  }
}
.manualSpLocationDiv {
  display: flex;
  width: 100%;

  & > div {
    display: flex;
    flex-direction: column;
    width: 49%;
    margin-right: 10px;

    .genericSearchbleDropdown {
      width: 100%;
      border-color: 1px solid rgba(0, 0, 0, 0.85);
    }
    .select {
      width: 100%;
      border-color: 1px solid rgba(0, 0, 0, 0.85);
    }
  }
}
