.SBinput {
    font-family: Apercu Pro;
    font-size: 16px;
    padding: 4px;
    width: 206px;
    height: 38px;
    border: 1px solid #000000;
    box-sizing: border-box;
    padding-left: 12px;
}
.inputLabel {
    font-family: Apercu Pro;
    font-size: 16px;
    padding-top: 20px;
    width: 67px;
    height: 21px;
    left: 0px;
    top: 5.5px;
}
