.rmaDashModal {
  display: block;
  position: fixed;
  z-index: 1;
  padding-top: 70px;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-height: 600px;
  max-width: 750px;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.modalButton {
  margin: 10px;
  background: #377a80;
  padding: 4px 6px;
  border-radius: 6px;
  font-family: Apercu Pro;
  font-size: 16px;
  lineheight: 150%;
  color: #ffffff;
}

.rmaDashModalTable {
  border-collapse: collapse;
}

.rmaDashModalTabletd,
.rmaDashModalTableth {
  border: 1px solid #dddddd;
  text-align: left;
  margin: 0;
  width: 300px;
  padding: 20px;
}

.rmaDashModalTabletr:nth-child(even) {
  background-color: #f0f0f5;
}

.rmaDashModalTabletr {
  margin: 0;
}

.rmaDashModalTableBody {
  display: block;
  overflow-y: scroll;
  height: 450px;
  width: 100%;
}
