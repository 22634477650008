.MessageModal {
  display: block;
  position: fixed;
  z-index: 1;
  padding-top: 130px;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.5); /* Black w/ opacity */
}

/* Modal Content */
.modal-body {
  background-color: #ffffff;
  margin: auto;
  padding: 20px;
  border: 1px solid #000000;
  height: 320px;
  width: 565px;
  box-shadow: 3px 3px 0px rgba(0, 0, 0, 0.25);
}

.Modal-header {
  width: 100%;
  height: 28px;
  text-align: left;
  padding-left: 15px;
  font-size: 24px;
  color: #000000;
}

.Modal-content {
  font-family: Apercu Pro;
  font-size: 16px;
  lineheight: 150%;

  display: flex;
  color: #000000;
  width: 100%;
  height: 485px;
  padding: 15px;
}

.Modal-footer {
  float: right;
}

.modalButton {
  margin: 5px;
  height: 32px;
  padding: 4px 12px;
  border-radius: 6px;
  font-family: Apercu Pro;
  font-size: 16px;
}

.modalButtonForward {
  background: #377a80;
  color: #ffffff;
  font-size: 16px;
  lineheight: 150%;
}
.modalButtonBackward {
  background: #ffffff;
  color: #377a80;
  font-size: 16px;
  lineheight: 150%;
}

.ModalTitle {
  font-family: Apercu Pro;
  font-style: Light;
  lineheight: 135%;
  font-size: 24px;
  align: Left;
  vertical-align: Center;
}
