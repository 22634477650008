.rmaadhocdiv {
  padding-top: 50px;
  width: 500px;
  height: 500px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.rmaadhocchilddiv {
  width: 100%;
  margin-bottom: 30px;
}

.rmaadhoctitle {
  font-family: Apercu Pro;
  font-size: 40px;
  lineheight: 115%;
  color: #000000;
}

.rmaadhoclabel {
  font-family: Apercu Pro;
  font-size: 16px;
  color: #000000;
  margin-top: 15px;
  lineheight: 1%;
}

.rmaadhocinput {
  width: 500px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #b6b5b5;
  box-sizing: border-box;
  padding-left: 12px;
  padding-top: 5px;
  font-family: Apercu Pro;
  font-size: 16px;
  color: #000000;
}

.rmaadhocbuttondiv {
  width: 450px;
}

.rmaadhocbutton {
  width: 97px;
  height: 48px;
  border-radius: 6px;
  margin-right: 10px;
  border-color: #377a80;
}
.rmaadhocForward {
  background: #377a80;
  color: #ffffff;
}
.rmaadhocBackward {
  background: #ffffff;
  color: #377a80;
}
.addPart {
  background: rgba(55, 122, 128, 1);
}

.selectRadio {
  padding: 10px;
}

.trukRadioButtonMainContainer {
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-bottom: 30px;
}
