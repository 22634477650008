.title {
  font-family: Apercu Pro;
  font-size: 40px;
  color: #000000;
  margin: 0px;
}

.leftdiv {
  float: left;
  height: fit-content;
  margin-bottom: 1rem;
  width: 400px;
}

.rightdiv {
  height: 46px;
  width: 100%;
}

.leftSearchdiv {
  flex: 1;
  display: flex;
}

.rightSearchdiv {
  flex: 4;
  display: flex;
}

.incoming {
  float: left;
  height: 48px;
  width: 89px;
  padding: 12px;
}

.outgoing {
  height: 48px;
  width: 89px;
  padding: 12px;
}

.selectionHighlighter {
  border-bottom: 4px solid;
}

.selectiondiv {
  height: 48px;
  width: 100%;
  margin-top: 28px;
  font-size: 16px;
  lineheight: 150%;
  display: flex;
  align-items: center;
  text-align: justify;
  border-bottom: 4px solid;
  border-color: #f0f0f5;
}
.transfersdiv {
  width: 100%;
  margin-top: 16px;
}

.searchdiv {
  display: flex;
  width: 95vw;
  margin-top: 24px;
}

.dropdowndiv {
  width: inherit;
  padding-top: 20px;
}

.tablediv {
  width: inherit;
  padding-bottom: 30px;
  padding-top: 20px;
}

.rmaDashButton {
  float: right;
  margin-top: 10px;
  font-family: Apercu Pro;
  font-size: 16px;
  border-style: 0.5px;
  border-color: teal;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 3px 15px;
  color: teal;
}

.rmaDashboardSearch {
  height: 52px;
  width: calc(100%);
  width: -moz-calc(100% - 50px);
  border-color: #616161;
  border-radius: 3px;
  background: url(../../../assets/icons8-search-30.svg) no-repeat scroll 10px 10px;
  padding-left: 50px;
}

.transferDashboardIncomingSearch {
  height: 52px;
  width: calc(100%);
  border-color: #616161;
  border-radius: 3px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  padding-left: 20px;
}
.transferDashboardIncomingDropdown {
  height: 52px;
  width: 100%;
  border-color: #616161;
  border-radius: 3px;
  border: 2px solid;
  border-right: 0px;
  padding-left: 50px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  background: url(../../../assets/icons8-search-30.svg) no-repeat scroll 10px 10px;
}
.searchdropdowndiv {
  padding-top: 10px;
  padding-right: 10px;
  float: left;
  height: 40px;
  min-width: 400px;
  margin-bottom: 30px;
}

.searchdropdownrightdiv {
  padding-top: 10px;
  height: 46px;
}

.rmadashboardtable {
  border-spacing: 0px;
  border-bottom: teal 1px solid;
  margin-bottom: 10px;
  width: 100%;
  .pagination {
    padding: 0.5rem;
  }
}

.dashrmatr:nth-child(odd) {
  background-color: #f0f0f5;
}

.dashrmatr {
  height: 52px;
  padding: 5px;
}

.paginationdiv {
  float: right;
}

.rmaDashboardTableHeader {
  text-align: left;
  font-family: Apercu Pro;
  font-size: 16px;
  padding: 0px 16px;
}
.rmaDashBoardTableData {
  padding: 0px 16px;
}

.ant-select-selector {
  border: 0px solid #000000;
  transition: none;
  height: 32px;
}
.genericDropdown {
  max-width: 100px;
  float: left;
  padding-right: 10px;
}
