.ScanNavLabel {
  position: static;
  width: 220px;
  height: 12px;
  left: 32px;
  top: 0px;
  font-family: Apercu Pro;
  font-size: 16px;
  lineheight: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #377a80;
  flex: none;
  order: 1;
  align-self: center;
  margin: 0px 0px;
  a:hover {
    color: #377a80;
  }
}
.scanLeftArraow {
  position: static;
  width: 24px;
  height: 24px;
  left: 0px;
  top: 0px;
  flex: none;
  order: 0;
  align-self: center;
  margin: 8px 0px;
}

.rmaNumberLabel {
  width: 100%;
  height: 46px;
  left: 24px;
  font-family: Apercu Pro;
  font-size: 40px;
  lineheight: 115%;
  display: flex;
  align-items: center;
  color: #000000;
  margin-top: 16px;
}

.scanParentDiv {
  margin-top: 20px;
  width: 453px;
  height: 682px;
  left: 24px;
  border: 1px solid #a5aaaf;
  box-sizing: border-box;
  padding: 16px;
  float: left;
  .title {
    width: 169px;
    height: 24px;
    font-family: Apercu Pro;
    font-size: 20px;
    lineheight: 120%;
    display: flex;
    align-items: center;
    color: #000000;
  }
  .title2 {
    margin-top: 24px;
    margin-bottom: 6px;
    width: 169px;
    height: 24px;
    font-family: Apercu Pro;
    font-size: 16px;
    lineheight: 50%;
    display: flex;
    align-items: center;
    color: #000000;
  }
  .scanbox {
    width: 100%;
    height: 48px;
    border: 2px solid #377a80;
    box-sizing: border-box;
    outline: none;
    font-size: 24px;
  }
  .scanMessage {
    width: 100%;
    padding: 0px 4px;
    height: 21px;
    background: #db1b1b;
    margin: 0px 0px;
    border: 0px;
    font-family: Apercu Pro;
    font-size: 14px;
    lineheight: 150%;
    color: #ffffff;
  }
  .buttonDisabled {
    position: absolute;
    top: 800px;
    display: flex;
    display: flex;
    flex-direction: column;
    padding: 12px 16px;

    position: absolute;
    width: 421px;
    height: 48px;
    .buttonText {
      position: static;
      width: 138px;
      height: 24px;
      left: 141.5px;
      top: 12px;
      font-family: Apercu Pro;
      font-size: 16px;
      lineheight: 150%;
      display: flex;
      align-items: center;
      text-align: center;
      color: #a5aaaf;
      flex: none;
      order: 0;
      align-self: center;
      margin: 0px 10px;
    }
  }
  .buttonEnabled {
    position: absolute;
    top: 800px;
    display: flex;
    flex-direction: column;
    padding: 12px 16px;
    position: absolute;
    width: 420px;
    height: 48px;
    background: #377a80;
    border-radius: 6px;
    .buttonText {
      position: static;
      width: 138px;
      height: 24px;
      left: 141.5px;
      top: 12px;
      font-family: Apercu Pro;
      font-size: 16px;
      lineheight: 150%;
      display: flex;
      align-items: center;
      text-align: center;
      color: #ffffff;
      flex: none;
      order: 0;
      align-self: center;
      margin: 0px 10px;
    }
  }
}
.partsDiv {
  float: right;
  width: calc(100vw - 535px);
  margin-top: 20px;
  height: 698px;
  padding-left: 16px;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 16px;
  .title {
    font-family: Apercu Pro;
    font-size: 20px;
    lineheight: 120%;
    display: flex;
    align-items: center;
    color: #000000;
  }
  .partsContainer {
    height: 642px;
    padding-top: 20px;
    .partContainer {
      width: 100%;
      min-height: 52px;
      border-bottom: 1px solid #a5aaaf;
      flex: none;
      align-self: center;
      margin: 0px 0px;
      font-size: 16px;
      lineheight: 150%;
      .partInfo {
        padding: 14px;
      }
    }

    .rmascanchildtablediv {
      padding: 0px 10px;
      .rmascanchildtable {
        width: 100%;
        tr {
          height: 52px;
          td {
            padding: 10px;
          }
          .serial {
            padding: 6px;
            margin: auto;
            align-self: center;
            lineheight: 100%;
            height: 30px;
          }
          .status {
            padding: 6px;
            margin: auto;
            flex: none;
            lineheight: 150%;
            font-size: 14px;
            height: 30px;
          }
          .delete {
            padding: 6px;
            width: 24px;
            height: 24px;
          }
          .quantity {
            text-align: right;
          }
        }
        tr:nth-child(odd) {
          background: #f0f0f5;
        }
      }
    }
  }
}

.ScanContent {
  height: 700px;
}

.printerImage {
  width: 24px;
  height: 24px;
  align-self: right;
}

.printText {
  width: 220px;
  height: 24px;
  font-family: Apercu Pro;
  font-size: 16px;
  align-items: center;
  text-align: center;
  color: #377a80;
  align-self: center;
  margin-left: 3px;
  margin a:hover {
    color: #377a80;
  }
}

.modal-body {
  width: 375px;
  height: 282px;
}

.Modal-content {
  height: 150px;
}

.Modal-input {
  height: 48px;
  width: 303px;
  background: #ffffff;
  border: 1.5px solid #000000;
  box-sizing: border-box;
  padding: 4px 8px;
}

.validationModalBody {
  background-color: #ffffff;
  margin: auto;
  padding: 20px;
  border: 1px solid #000000;
  height: 460px;
  width: 830px;
  box-shadow: 3px 3px 0px rgba(0, 0, 0, 0.25);
}

.validationModalContent {
  font-family: Apercu Pro;
  font-size: 16px;
  lineheight: 150%;
  display: flex;
  color: #000000;
  width: 100%;
  height: 185px;
  padding: 25px;
  height: 360px;
  flex-direction: column;
  flex-wrap: wrap;
  overflow-y: auto;
  p {
    text-align: left;
    padding: 1em;
  }
}

.unfulfilledReasonsTable {
  width: 100%;
  th {
    text-align: left;
  }
  td {
    padding-top: 10px;
    text-align: left;
    min-width: 200px;
  }
  input {
    width: 330px;
    height: 70px;
  }
}

.successModalBody {
  background-color: #ffffff;
  margin: auto;
  padding: 20px;
  border: 1px solid #000000;
  height: 260px;
  width: 830px;
  box-shadow: 3px 3px 0px rgba(0, 0, 0, 0.25);
}

.successModalContent {
  font-family: Apercu Pro;
  font-size: 16px;
  lineheight: 150%;
  display: flex;
  color: #000000;
  width: 100%;
  height: 185px;
  padding: 15px;
  height: 160px;
  p {
    text-align: left;
  }
}

.fromToInfoDiv {
  margin-top: 40px;
  .labeltext {
    margin-bottom: 0px;
    lineheight: 1.2;
  }
  .locationNameText {
    font-weight: 400;
    font-size: 24px;
    margin-bottom: 1em;
    lineheight: 1.2;
  }
}
