.loader {
    position:absolute;
    border: 6px solid #f3f3f3;
    border-radius: 50%;
    border-top: 6px solid #333f48;
    width: 100px;
    height: 100px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    left:calc(50vw - 50px);
    top:calc(50vh - 50px);
    /* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  }
  .LoadBackgrounnd{
    display: block;
    position: fixed;
    z-index: 1;
    padding-top: 130px;
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.5); /* Black w/ opacity */
  }