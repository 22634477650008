.SBSearchableinput {
    font-family: Apercu Pro;
    font-size: 16px;
    padding: 2px;
    width: 250px;
    height: 32px;
    border: 1px solid #000000;
    box-sizing: border-box;
    padding-left: 12px;
    background-image:url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat;
    background-size: .6em;
    background-position: calc(100% - 1em) center;
    padding-left: 12px;
}
.inputLabel {
    font-family: Apercu Pro;
    font-size: 16px;
    padding-top: 20px;
    width: 67px;
    height: 21px;
    left: 0px;
    top: 5.5px;
}

.ant-select-selector::selection{
    background: #000000;
}

.genericSearchbleDropdown{
        font-family: 'Apercu Pro';
        font-size: 16px;
        width: 270px;
        height: 33.5px;
        border: 1px solid #000000;
        background: #000000;
}
.genericDropdown{
    float: 'left';
    padding-right:'12px' 
}