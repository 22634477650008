.errorAlert {
    background: #db1b1b;
    width: 100%;
    height: 40px;
    margin-bottom: 2px;
    font-family: Apercu Pro;
    font-size: 16px;
    padding-top: 10px;
    padding-left: 10px;
    color: #ffffff;
}

.successAlert {
    background: #37e7a7;
    width: 100%;
    height: 40px;
    margin-bottom: 2px;
    font-family: Apercu Pro;
    font-size: 16px;
    padding-top: 10px;
    padding-left: 10px;
}

.infoAlert {
    width: 100%;
    height: 40px;
    font-family: Apercu Pro;
    font-size: 16px;
    padding-top: 10px;
    padding-left: 10px;
}

.warningAlert {
    width: 100%;
    height: 40px;
    font-family: Apercu Pro;
    font-size: 16px;
    padding-top: 10px;
    padding-left: 10px;
}

.AlertDiv {
    width: 100%;
}

.alertImagediv {
    float: right;
    padding-right: 16px;
    padding-top: 8px;
}
