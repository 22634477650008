.countsByStatusDiv {
  float: right;
  height: 40px;
  margin: 5px;
  padding: 5px;
  border: 1px solid black;
  border-radius: 5px;
  opacity: 0.8;
}
.quantityEditBox {
  height: 40px;
  float: right;
  width: 300px;
  padding: 5px;
  > tr {
    border-bottom: 10px solid transparent;
    > td {
      text-align: start;
    }
  }
}
.quantityEditInputBox {
  width: 40px;
  margin-left: 40px;
}
.Modal-content {
  font-family: Apercu Pro;
  font-size: 16px;
  lineheight: 150%;
  display: flex;
  color: #000000;
  width: 100%;
  height: 185px;
  padding: 15px;
  text-align: center;
}

.truckTitle {
  padding-top: 10px;
  width: 469px;
  height: 24px;
  font-family: Apercu Pro;
  font-size: 25px;
  lineheight: 120%;
  display: flex;
  align-items: center;
  color: #000000;
}
.transferbuttonDisabled {
  position: absolute;
  top: 720px;
  display: flex;
  display: flex;
  flex-direction: column;
  padding: 12px 36px;
  background: #e6e6eb;
  position: absolute;
  width: 400px;
  height: 48px;
  border-radius: 6px;
  left: 50px;
  .buttonText {
    position: static;
    width: 100px;
    height: 24px;
    left: 141.5px;
    top: 12px;
    font-family: Apercu Pro;
    font-size: 16px;
    lineheight: 150%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #a5aaaf;
    flex: none;
    order: 0;
    align-self: center;
    margin: 0px 10px;
  }
}
.transferbuttonEnabled {
  position: absolute;
  top: 720px;
  display: flex;
  flex-direction: column;
  padding: 12px 36px;
  position: absolute;
  width: 400px;
  height: 48px;
  background: #377a80;
  border-radius: 6px;
  left: 50px;
  .buttonText {
    position: static;
    width: 100px;
    height: 24px;
    left: 141.5px;
    top: 12px;
    font-family: Apercu Pro;
    font-size: 16px;
    lineheight: 150%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    flex: none;
    order: 0;
    align-self: center;
    margin: 0px 10px;
  }
}

.fromToInfoDiv {
  margin-top: 40px;
  .labeltext {
    margin-bottom: 0px;
    lineheight: 1.2;
  }
  .locationNameText {
    font-weight: 400;
    font-size: 24px;
    margin-bottom: 1em;
    lineheight: 1.2;
  }
}

.modal-body {
  width: 375px;
  height: 412px;
}

.Modal-content {
  height: 300px;
}

.Modal-input {
  height: 48px;
  width: 303px;
  background: #ffffff;
  border: 1.5px solid #000000;
  box-sizing: border-box;
  padding: 4px 8px;
}

.validationModalBody {
  background-color: #ffffff;
  margin: auto;
  padding: 20px;
  border: 1px solid #000000;
  height: 460px;
  width: 830px;
  box-shadow: 3px 3px 0px rgba(0, 0, 0, 0.25);
}

.validationModalContent {
  font-family: Apercu Pro;
  font-size: 16px;
  lineheight: 150%;
  display: flex;
  color: #000000;
  width: 100%;
  height: 185px;
  padding: 15px;
  height: 360px;
  overflow-y: auto;
  p {
    text-align: left;
  }
}

.unfulfilledReasonsTable {
  width: 100%;
  th {
    text-align: left;
  }
  td {
    padding-top: 10px;
    text-align: left;
    min-width: 200px;
  }
  input {
    width: 330px;
    height: 70px;
  }
}

.successModalBody {
  background-color: #ffffff;
  margin: auto;
  padding: 20px;
  border: 1px solid #000000;
  height: 260px;
  width: 830px;
  box-shadow: 3px 3px 0px rgba(0, 0, 0, 0.25);
}

.successModalContent {
  font-family: Apercu Pro;
  font-size: 16px;
  lineheight: 150%;
  display: flex;
  color: #000000;
  width: 100%;
  height: 185px;
  padding: 15px;
  height: 160px;
  p {
    text-align: left;
  }
}

.TransferNumberTitle {
  margin-top: 16px;
}
