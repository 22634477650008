.rmaShipStatusStyle {
  background-color: black;
  color: white;
  font-size: 14px;
  padding: 3px 4px;
}
.rmaInprogressStyle {
  background-color: #f9d51c;
  color: black;
  font-size: 14px;
  padding: 3px 4px;
}

.rmaFinishStyle {
  background-color: #37e7a7;
  color: black;
  font-size: 14px;
  padding: 3px 4px;
}

.rmaCreatedStyle {
  background-color: #e6e6eb;
  color: black;
  font-size: 14px;
  padding: 3px 4px;
}

.nonActionItem {
  padding-right: 16px;
  font-family: Apercu Pro;
  lineheight: 150%;
  font-size: 16px;
  color: #377a80;
  text-decoration-line: underline;
  color: #377a80;
}

.actionItem {
  padding-right: 16px;
  font-family: Apercu Pro;
  lineheight: 150%;
  font-size: 16px;
  color: #377a80;
  text-decoration-line: underline;
  color: #377a80;
  cursor: pointer;
}

.paginationbuttonEnabled {
  background-color: white;
  border: 0px;
  font-family: Apercu Pro;
  font-size: 14px;
  lineheight: 150%;
  color: #377a80;
}

.paginationbuttonDisabled {
  background-color: white;
  border: 0px;
  font-family: Apercu Pro;
  font-size: 14px;
  lineheight: 150%;
  color: #a5aaaf;
}
.rmaDashBoardTableData {
  padding: 0px 16px;
}

.selectiondiv {
  cursor: pointer;
}

.disabled-link {
  padding-right: 16px;
  font-family: Apercu Pro;
  font-size: 16px;
  color: #9c9c9c;
  text-decoration-line: underline;
  color: #9c9c9c;
  pointer-events: none;
}
